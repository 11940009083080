import {
  type TechnicalSkill,
  TechnicalSkillCategory,
  TechnicalSkillExperienceRating
} from './types'

export const NODE: TechnicalSkill = {
  title: 'Node.js',
  rating: TechnicalSkillExperienceRating.E5,
  category: TechnicalSkillCategory.SERVER_SIDE
}
export const EXPRESS: TechnicalSkill = {
  title: 'Express',
  rating: TechnicalSkillExperienceRating.E5,
  category: TechnicalSkillCategory.SERVER_SIDE
}
export const GRAPHQL: TechnicalSkill = {
  title: 'GraphQL',
  rating: TechnicalSkillExperienceRating.E5,
  category: TechnicalSkillCategory.SERVER_SIDE
}
export const APOLLO_CLIENT: TechnicalSkill = {
  title: 'Apollo',
  rating: TechnicalSkillExperienceRating.E4,
  category: TechnicalSkillCategory.SERVER_SIDE
}
export const KOA: TechnicalSkill = {
  title: 'Koa',
  rating: TechnicalSkillExperienceRating.E4,
  category: TechnicalSkillCategory.SERVER_SIDE
}
export const NEXT: TechnicalSkill = {
  title: 'Next.js',
  rating: TechnicalSkillExperienceRating.E3,
  category: TechnicalSkillCategory.SERVER_SIDE
}
export const NEST: TechnicalSkill = {
  title: 'NestJS',
  rating: TechnicalSkillExperienceRating.E3,
  category: TechnicalSkillCategory.SERVER_SIDE
}

export const VANILLA_JS: TechnicalSkill = {
  title: 'Vanilla Javascript',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E5
}
export const REACT: TechnicalSkill = {
  title: 'React',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E5
}
export const ANGULAR: TechnicalSkill = {
  title: 'Angular',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E2
}
export const REACT_NATIVE: TechnicalSkill = {
  title: 'React Native',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E5
}
export const APOLLO: TechnicalSkill = {
  title: 'Apollo',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E4
}
export const REDUX: TechnicalSkill = {
  title: 'Redux',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E4
}
export const RXJS: TechnicalSkill = {
  title: 'RxJS',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E3
}
export const IONIC: TechnicalSkill = {
  title: 'Ionic',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E4
}
export const MFE: TechnicalSkill = {
  title: "MFE's",
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E3
}
export const SCSS: TechnicalSkill = {
  title: 'SCSS',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E4
}
export const STYLED_COMPONENTS: TechnicalSkill = {
  title: 'Styled Components',
  category: TechnicalSkillCategory.CLIENT_SIDE,
  rating: TechnicalSkillExperienceRating.E2
}

export const PGSQL: TechnicalSkill = {
  title: 'PostgreSQL',
  category: TechnicalSkillCategory.DB,
  rating: TechnicalSkillExperienceRating.E5
}
export const MYSQL: TechnicalSkill = {
  title: 'MySQL',
  category: TechnicalSkillCategory.DB,
  rating: TechnicalSkillExperienceRating.E5
}
export const MONGO: TechnicalSkill = {
  title: 'Mongo',
  category: TechnicalSkillCategory.DB,
  rating: TechnicalSkillExperienceRating.E4
}
export const DYNAMO: TechnicalSkill = {
  title: 'Dynamo',
  category: TechnicalSkillCategory.DB,
  rating: TechnicalSkillExperienceRating.E3
}
export const KAFKA: TechnicalSkill = {
  title: 'Kafka',
  category: TechnicalSkillCategory.DB,
  rating: TechnicalSkillExperienceRating.E3
}

export const AWS: TechnicalSkill = {
  title: 'AWS',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E5
}
export const AWS_EKS: TechnicalSkill = {
  title: 'AWS EKS',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E5
}
export const AWS_CLOUDFRONT: TechnicalSkill = {
  title: 'AWS CloudFront',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E4
}
export const AWS_LAMBDA_EDGE: TechnicalSkill = {
  title: 'AWS Lambda@Edge',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E4
}
export const AWS_S3: TechnicalSkill = {
  title: 'AWS S3',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E4
}
export const GCP: TechnicalSkill = {
  title: 'GCP',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E5
}
export const ZEPHR: TechnicalSkill = {
  title: 'Zephr',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E2
}
export const KUBERNETES: TechnicalSkill = {
  title: 'Kubernetes',
  category: TechnicalSkillCategory.INFRASTRUCTURE,
  rating: TechnicalSkillExperienceRating.E3
}

export const LERNA: TechnicalSkill = {
  title: 'Lerna',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E2
}
export const DOCKER: TechnicalSkill = {
  title: 'Docker',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E4
}
export const GITLABCI: TechnicalSkill = {
  title: 'GitLab CI',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E4
}
export const JEST: TechnicalSkill = {
  title: 'Jest',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E5
}
export const CYPRESS: TechnicalSkill = {
  title: 'Cypress',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E4
}
export const RTL: TechnicalSkill = {
  title: 'React Testing Library',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E4
}
export const ENZYME: TechnicalSkill = {
  title: 'Enzyme',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E3
}
export const TERRAFORM: TechnicalSkill = {
  title: 'Terraform',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E3
}
export const TYPESCRIPT: TechnicalSkill = {
  title: 'TypeScript',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E3
}
export const SUPERTEST: TechnicalSkill = {
  title: 'Supertest',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E4
}
export const GRUNT: TechnicalSkill = {
  title: 'Grunt',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E2
}
export const GULP: TechnicalSkill = {
  title: 'Gulp',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E2
}
export const CUCUMBER_JS: TechnicalSkill = {
  title: 'Cucumber.js',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E2
}
export const BABEL: TechnicalSkill = {
  title: 'Babel',
  category: TechnicalSkillCategory.TOOLING,
  rating: TechnicalSkillExperienceRating.E2
}

export const TDD: TechnicalSkill = {
  title: 'TDD',
  category: TechnicalSkillCategory.GENERAL,
  rating: TechnicalSkillExperienceRating.E5
}
export const PAIR_PROGRAMMING: TechnicalSkill = {
  title: 'Pair Programming',
  category: TechnicalSkillCategory.GENERAL,
  rating: TechnicalSkillExperienceRating.E4
}
export const SCRUM: TechnicalSkill = {
  title: 'Scrum',
  category: TechnicalSkillCategory.GENERAL,
  rating: TechnicalSkillExperienceRating.E4
}
export const MENTORING: TechnicalSkill = {
  title: 'Mentoring',
  category: TechnicalSkillCategory.GENERAL,
  rating: TechnicalSkillExperienceRating.E4
}
export const RESTFUL_API: TechnicalSkill = {
  title: 'RESTful API Design',
  category: TechnicalSkillCategory.GENERAL,
  rating: TechnicalSkillExperienceRating.E5
}
export const GRAPHQL_API: TechnicalSkill = {
  title: 'Technical Documentation',
  category: TechnicalSkillCategory.GENERAL,
  rating: TechnicalSkillExperienceRating.E4
}
export const SOLUTION_ARCH: TechnicalSkill = {
  title: 'Solution Architecture',
  category: TechnicalSkillCategory.GENERAL,
  rating: TechnicalSkillExperienceRating.E3
}
