export enum TechnicalSkillExperienceRating {
  E1,
  E2,
  E3,
  E4,
  E5,
}

export enum TechnicalSkillCategory {
  CLIENT_SIDE = 'Client Side',
  SERVER_SIDE = 'Server Side',
  DB = 'Database',
  INFRASTRUCTURE = 'Infrastructure',
  TOOLING = 'Tooling',
  GENERAL = 'General',
}

export interface TechnicalSkill {
  title: string
  category: TechnicalSkillCategory
  rating: TechnicalSkillExperienceRating
}

export interface TechnicalSkillsGroup {
  title: string
  skills: TechnicalSkill[]
}
