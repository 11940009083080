import * as React from 'react'
import { TechnicalSkills } from './components/TechnicalSkills'
import './App.scss'
import { Experiences } from './components/Experiences'
import { Education } from './components/Educations'
import { EmailLink } from './components/EmailLink'
import { educations } from './data/education'

const App: React.FC = () => (
    <div className="App">
        <header>
            <div>
                <img width={140} height={140} className='no-print' src="/img/me.webp" alt="me"/>
            </div>
            <div>
                <h1>Joseph Gordon</h1>
                <h2>Full Stack Senior Javascript Engineer</h2>
                <EmailLink />
            </div>
        </header>
        <summary>
            Passionate and results-driven software engineer with over 10 years of experience, dedicated to delivering exceptional software solutions. I thrive on the challenges that come with crafting high-quality applications and possess a genuine love for the art of software development. As an engineer, I go beyond technical expertise, actively engaging with product owners to comprehend their vision and strike a balance between prompt delivery of user value and the pursuit of technical excellence. I am driven to create future-proof solutions that not only meet current needs but also anticipate future requirements.
        </summary>
        <aside className="dark">
            <div>
                <TechnicalSkills />
            </div>
        </aside>
        <Experiences />
        <Education educations={educations} />
    </div>
)

export default App
