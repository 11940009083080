import { format, isToday } from 'date-fns'
import * as React from 'react'
import { experiences } from '../../data/experience/experience'
import type { Experience as ExperienceType } from '../../data/experience/types'
import './Experiences.scss'
import { plainTextToClassname } from '../../utils/plain-text-to-classname'
import {
  type TechnicalSkill,
  TechnicalSkillCategory
} from '../../data/technical-skills/types'
import { sort } from 'ramda'
import { EmailLink } from '../EmailLink'
import { Expandable } from '../Expandable'

const formatDate = (date: Date): string => {
  if (isToday(date)) {
    return 'Current'
  }
  return format(date, 'MMMM yyyy')
}

const skillCategoryPrioritisation: Record<TechnicalSkillCategory, number> = {
  [TechnicalSkillCategory.CLIENT_SIDE]: 1,
  [TechnicalSkillCategory.SERVER_SIDE]: 2,
  [TechnicalSkillCategory.DB]: 3,
  [TechnicalSkillCategory.INFRASTRUCTURE]: 4,
  [TechnicalSkillCategory.TOOLING]: 5,
  [TechnicalSkillCategory.GENERAL]: 5
}

const sortTechnicalSkills = (skills: TechnicalSkill[]): TechnicalSkill[] =>
  sort((a, b) => {
    const aCategoryPriority = skillCategoryPrioritisation[a.category]
    const bCategoryPriority = skillCategoryPrioritisation[b.category]
    const categoryPriority = aCategoryPriority - bCategoryPriority
    const skillPriority = a.rating > b.rating ? -1 : 1

    return categoryPriority === skillPriority ? skillPriority : categoryPriority
  }, skills)

interface ExperienceProps extends ExperienceType {
  index: number
}

export const Experience: React.FC<ExperienceProps> = ({
  jobTitle,
  dates,
  company,
  technologies,
  contributions,
  projectDescription,
  index
}: ExperienceProps) => {
  return (
    <div className="Experience">
        <Expandable
            defaultExpanded={index === 0}
            header={<>
              <div className="image">
                <img width={37} height={37} src={company.image} alt={`${company.name} logo`} />
              </div>
              <div className="head">
                <div className="text">
                  <h4>{jobTitle}</h4>
                  <h5>
                    <time dateTime={dates.start.toDateString()}>
                      {formatDate(dates.start)}
                    </time>{' '}
                    -{' '}
                    <time dateTime={dates.start.toDateString()}>
                      {formatDate(dates.end)}
                    </time>
                  </h5>
                </div>
                <div>
                  <h6>{company.name}</h6>
                  {/* <h6> */}
                  {/*  {location} ({locale}) */}
                  {/* </h6> */}
                </div>
              </div>
            </>}
        >
          <summary >{projectDescription}</summary>
          <ul className='contributions'>
            {contributions.map((contribution) => (
                <li key={contribution}>{contribution}</li>
            ))}
          </ul>
          <ul className='technologies'>
            {sortTechnicalSkills(technologies).map((technology) => (
                <li
                    key={technology.title}
                    className={`technologies skill-category ${plainTextToClassname(
                        technology.category
                    )}`}
                >
                  {technology.title}
                </li>
            ))}
          </ul>
        </Expandable>
    </div>
  )
}

export const Experiences: React.FC = () => {
  return (
    <div className="Experiences">
        <Expandable defaultExpanded header={<h3>Experience</h3>}>
                {experiences.map((experience, index) => (
                    <Experience key={experience.projectDescription} {...experience} index={index} />
                ))}
                <p className="experience-footnote">
                    Referees and more experience dating back to 2013 available upon request
                </p>
                <EmailLink className="no-print">Get in touch</EmailLink>
                <EmailLink className="only-print" />
        </Expandable>
    </div>
  )
}
