import * as React from 'react'
import './TechnicalSkills.scss'
import type {
  TechnicalSkill
} from '../../data/technical-skills/types'
import { TechnicalSkillExperienceRating } from '../../data/technical-skills/types'
import { technologySkillsGroups } from '../../data/technical-skills/technical-skills-groups'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { plainTextToClassname } from '../../utils/plain-text-to-classname'
import { useState } from 'react'
import { Expandable } from '../Expandable'

interface TechnicalSkillsListProps {
  skills: TechnicalSkill[]
}

const TechnicalSkillsList: React.FC<TechnicalSkillsListProps> = ({ skills }: TechnicalSkillsListProps) => {
  return (
    <ul>
      {skills
        .filter(
          (skill) =>
            skill.rating >= TechnicalSkillExperienceRating.E3
        )
        .map((skill) => (
          <li key={skill.title}>
            <span>{skill.title}</span>
            <span>
              {Array.from({ length: 5 }).map((_, i) =>
                i <= skill.rating
                  ? (
                  <FontAwesomeIcon
                    key={i}
                    size={'xs'}
                    icon={icon({ style: 'solid', name: 'heart' })}
                  />
                    )
                  : (
                  <FontAwesomeIcon
                    key={i}
                    size={'xs'}
                    icon={icon({ style: 'regular', name: 'heart' })}
                  />
                    )
              )}
            </span>
          </li>
        ))}
    </ul>
  )
}

export const TechnicalSkills: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  return (
      <Expandable defaultExpanded header={(
          <h3 onClick={() => { setIsExpanded(!isExpanded) }}>
              Skills
          </h3>
      )}>
          <div className="TechnicalSkills">
              <div className={`TechnicalSkillsGroupContainer${isExpanded ? ' expanded' : ' collapsed'}`}>
                  {technologySkillsGroups.map(([title, skills]) => (
                      <div key={title}>
                          <h4 className={`skill-category ${plainTextToClassname(title)}`}>
                              {title}
                          </h4>
                          <TechnicalSkillsList skills={skills} />
                      </div>
                  ))}
              </div>
          </div>
      </Expandable>
  )
}
