import * as React from 'react'
import classNames from 'classnames'
import './EmailLink.scss'

const EMAIL = 'me+online@josephgordon.co.uk'

interface EmailLinkProps {
  className?: string
  children?: React.ReactNode
}
export const EmailLink: React.FC<EmailLinkProps> = ({ children = 'me@josephgordon.co.uk', className }: EmailLinkProps) => (
    <address className={classNames(['EmailLink', className])}>
      <a href={`mailto:${EMAIL}`}>{children}</a>
    </address>
)
