import { type TechnicalSkill } from '../technical-skills/types'

export interface Company {
  name: string
  image: string
}

export enum Employment {
  CONTRACT = 'CONTRACT',
  PERM = 'PERM',
}

export enum Locale {
  HYBRID = 'Hybrid',
  REMOTE = 'Remote',
  IN_OFFICE = 'In office',
}

export enum Location {
  LONDON = 'London',
  WHISTLER = 'Whistler',
  ZURICH = 'Zürich',
  MANCHESTER = 'Manchester',
}

export interface Experience {
  jobTitle: string
  employment: Employment
  projectDescription?: string
  contributions: string[]
  dates: {
    start: Date
    end: Date
  }
  locale: Locale
  company: Company
  location: Location
  technologies: TechnicalSkill[]
}

export type Experiences = Experience[]
