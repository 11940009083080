import {
  type TechnicalSkill
} from './types'

import * as TECHNICAL_SKILLS from './technical-skills'
import { compose, groupBy, ifElse, toPairs, values, sort } from 'ramda'

type TechnicalSkillsEntry = [string, TechnicalSkill[]]
type TechnicalSkillsEntryList = TechnicalSkillsEntry[]

const grouped: TechnicalSkillsEntryList = compose(
  sort<TechnicalSkillsEntry>(
    ifElse<[TechnicalSkillsEntry, TechnicalSkillsEntry], number, number>(
      ([a], [b]) => a > b,
      () => 1,
      () => -1
    )
  ),
  toPairs,
  groupBy<TechnicalSkill, string>((skill: TechnicalSkill) => skill.category),
  sort<TechnicalSkill>(
    ifElse<[TechnicalSkill, TechnicalSkill], number, number>(
      (a: TechnicalSkill, b: TechnicalSkill) => a.rating > b.rating,
      () => -1,
      () => 1
    )
  ),
  values
)(TECHNICAL_SKILLS)

export const technologySkillsGroups: TechnicalSkillsEntryList = grouped
