import React, { useState } from 'react'
import { Chevron } from '../../components/Chevron'
import './Expandable.scss'
interface ExpandableProps {
  header: React.ReactNode
  defaultExpanded?: boolean
}
export const Expandable: React.FC<ExpandableProps> = ({ children, header, defaultExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)

  return (
    <div className={`Expandable border-bottom ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className={`top${isExpanded ? ' border-bottom' : ''}`} onClick={() => { setIsExpanded(!isExpanded) }} >
          <div className={'header-content'}>
            { header }
          </div>
          <div className='chevron-container no-print'>
            <Chevron flipped={isExpanded} />
          </div>
      </div>
      <div className='content'>{ children }</div>
    </div>
  )
}
