import * as React from 'react'
import { type Educations } from '../../data/education'
import { Chevron } from '../Chevron'

export interface EducationProps {
  educations: Educations
}

export const Education: React.FC<EducationProps> = ({ educations }: EducationProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  return (
    <div className="Education border-bottom">
      <h3 onClick={() => { setIsExpanded(!isExpanded) }}>Education <Chevron flipped={isExpanded} /></h3>
      <ul className={isExpanded ? 'expanded' : 'collapsed'}>
        {educations.map((education) => (
          <li key={education.institution}>
            <h4>{education.institution}</h4>
            <p>{education.qualification}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}
