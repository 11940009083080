import {
  type Company,
  Employment,
  type Experience,
  type Experiences,
  Locale,
  Location
} from './types'
import {
  AWS_CLOUDFRONT,
  AWS_EKS,
  AWS_LAMBDA_EDGE,
  AWS_S3,
  BABEL,
  CYPRESS,
  DOCKER,
  ENZYME, EXPRESS,
  GCP,
  GITLABCI,
  GRAPHQL,
  GRAPHQL_API,
  IONIC,
  JEST,
  KAFKA,
  KOA,
  KUBERNETES,
  LERNA,
  MENTORING,
  MFE,
  MONGO,
  MYSQL,
  NODE,
  PAIR_PROGRAMMING,
  PGSQL,
  REACT,
  REACT_NATIVE,
  REDUX,
  RESTFUL_API,
  RTL,
  RXJS,
  SCRUM,
  SCSS,
  SOLUTION_ARCH,
  STYLED_COMPONENTS,
  SUPERTEST,
  TDD,
  TERRAFORM,
  TYPESCRIPT,
  VANILLA_JS,
  ZEPHR
} from '../technical-skills/technical-skills'

const EUROSTAR: Experience = {
  jobTitle: 'Senior Javascript Developer',
  projectDescription:
    'To work on the vital and urgent rebranding of the Eurostar website, following the recent acquisition of a european competitor.',
  dates: {
    start: new Date('2023-05-01'),
    end: new Date()
  },
  company: {
    name: 'Eurostar',
    image: '/img/eurostar.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.HYBRID,
  contributions: [
    'Contributed to a large, fast moving codebase whilst maintaining code stability.',
    'Worked with a large team of developers, designers, product managers and QA engineers to deliver a high quality product.',
    'Collaborated with the design team to ensure the UI was accessible and responsive.',
    'Spoke with the product team to ensure the UI was intuitive and met the business requirements.'
  ],
  technologies: [
    REACT,
    NODE,
    EXPRESS,
    TYPESCRIPT,
    JEST,
    DOCKER,
    CYPRESS,
    RTL,
    SUPERTEST,
    TDD,
    RESTFUL_API,
    PAIR_PROGRAMMING,
    SCRUM
  ]
}
const GFK: Experience = {
  jobTitle: 'Senior Full Stack Javascript Engineer',
  projectDescription:
    "To be a key contributor of a Platform team providing API's & UI components to teams building and maintaining an AI-powered SaaS analytics tool ",
  dates: {
    start: new Date('2022-07-01'),
    end: new Date('2023-05-01')
  },
  company: {
    name: 'GfK',
    image: '/img/gfk.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.HYBRID,
  contributions: [
    'Contributed to the continuous improvement of the platform, building UI (React) and API (Node) features to be used by various proposition teams within the product.',
    'Provided Javascript expertise to a team of 5 developers, either junior developers or senior developers less experienced in Javascript.',
    'Championed best practices and encouraging the team to continuously improve.',
    'Mentored & paired with the more junior members of the team to help them become strong team contributors.',
    'Collaborated with Product Managers to define requirements, informing them of technical complexities & trade-offs and discussing slices/options of feature delivery.',
    'Designed and presented architectural decisions for new large platform wide features to the various proposition teams and the architectural board.',
    'Rapidly developed POCs to present architectural proposals to proposition teams.',
    'Owned delivery of features, providing technical oversight from inception and UI design through to technical design & implementation',
    'Ensured code quality and stability by performing peer reviews, encouraging best coding practices, and writing & maintaining automated tests.',
    'Supported proposition teams in using platform APIs & UI components.',
    'Optimised agile planning and processes, keeping meetings focussed, encouraging prioritisation and WIP limits.',
    'Continuously working to optimise team ways of working.'
  ],
  technologies: [
    REACT,
    REDUX,
    MFE,
    PGSQL,
    NODE,
    EXPRESS,
    TYPESCRIPT,
    JEST,
    GCP,
    DOCKER,
    GITLABCI,
    CYPRESS,
    RTL,
    SUPERTEST,
    TDD,
    RESTFUL_API,
    SOLUTION_ARCH,
    MENTORING,
    PAIR_PROGRAMMING,
    SCRUM
  ]
}

const NEWS_UK: Company = {
  name: 'News UK (The Times)',
  image: '/img/thetimes.webp'
}

const NEWSUK_ZEPHR: Experience = {
  jobTitle: 'Senior Full Stack Engineer',
  projectDescription:
    'To lead a new integration with a subscription-management platform to build a new flexible paywall, allowing them to target new markets.',
  dates: {
    start: new Date('2022-01-01'),
    end: new Date('2022-07-01')
  },
  company: NEWS_UK,
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.HYBRID,
  contributions: [
    'Mentored & paired with the more junior members of the team.',
    'Built new flexible paywall features in React.',
    'Ensured effective communication between the team and stakeholders.',
    'Worked directly with heads of product to refine requirements.',
    'Designed and implemented a solution to allow non-subscribing users who meet specific criteria to read a limited number of free articles.',
    'Wrote and maintained documentation for stakeholders to configure a more flexible paywall.',
    'Managed the support relationship with external providers of the subscription-management platform.',
    'Implemented and deployed logic directly into Lambdas in their CDN.',
    'Ensured CDN uptime by maintaining unit and integration tests.'
  ],
  technologies: [
    AWS_CLOUDFRONT,
    TYPESCRIPT,
    JEST,
    RTL,
    TERRAFORM,
    REACT,
    NODE,
    EXPRESS,
    VANILLA_JS,
    AWS_LAMBDA_EDGE,
    ZEPHR,
    LERNA,
    GRAPHQL,
    TDD,
    SOLUTION_ARCH,
    MENTORING,
    PAIR_PROGRAMMING,
    SCRUM
  ]
}

const WORLD_REMIT: Experience = {
  jobTitle: 'Typescript Engineer',
  projectDescription:
    'To contribute to the greenfield development of a "Dynamic Form Manager" to allow the business easy management of hundreds of different form variations.',
  dates: {
    start: new Date('2021-08-01'),
    end: new Date('2021-12-01')
  },
  company: {
    name: 'World Remit',
    image:
      '/img/wr.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.HYBRID,

  contributions: [
    'Designed, implemented, and deployed RESTful microservices to provide the "Dynamic Form Manager" with data.',
    'Implemented features in a Scrum/Kanban environment.',
    'Maintained code quality and stability using TDD and writing and maintaining integration tests.',
    'Collaborating with internal stakeholders to discuss possible solutions'
  ],
  technologies: [
    TYPESCRIPT,
    JEST,
    REACT,
    NODE,
    KOA,
    DOCKER,
    KUBERNETES,
    GRAPHQL,
    GCP,
    TDD,
    SOLUTION_ARCH,
    RESTFUL_API
  ]
}

const NEWSUK_MAIN: Experience = {
  jobTitle: 'Senior Software Engineer',
  projectDescription:
    'To be a senior engineer in a flagship team, championing a business critical integration with Google.',
  dates: {
    start: new Date('2021-03-01'),
    end: new Date('2021-08-01')
  },
  company: NEWS_UK,
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.HYBRID,

  contributions: [
    'Incrementally implemented and delivered features to an existing codebase.',
    'Ensuring code quality and site uptime by writing and maintaining unit & integration tests.',
    'Worked with Product Managers and Business Analysts to understand complex authentication logic.',
    'Delivered technical presentations to share knowledge with the other teams affected by our work.',
    'Collaborated with the Head of Engineering to ideate on ways to simplify authentication architecture.',
    'Enabled the many junior members of the team to be strong contributors'
  ],
  technologies: [
    AWS_CLOUDFRONT,
    TERRAFORM,
    REACT,
    NODE,
    TYPESCRIPT,
    JEST,
    RTL,
    VANILLA_JS,
    AWS_LAMBDA_EDGE,
    LERNA,
    GRAPHQL,
    TDD,
    SOLUTION_ARCH,
    MENTORING,
    PAIR_PROGRAMMING,
    SCRUM
  ]
}

const RISKBASE: Experience = {
  jobTitle: 'Lead Developer',
  projectDescription:
    'To lead a team of 3 developers to bring a native rebuild of a web-view-based risk assessment phone and tablet app to production.',
  dates: {
    start: new Date('2020-09-01'),
    end: new Date('2021-03-01')
  },
  company: {
    name: 'Riskbase',
    image:
      '/img/riskbase.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.IN_OFFICE,

  contributions: [
    'Spent time mentoring & pair programming with the more junior team members.',
    'Led technical discussions for possible solutions to deliver critical features for the first release of the new app quickly.',
    'Brought code best practices and quality measures to a codebase that more closely resembled a POC',
    'Configured automated app deployment to IOS App Store, Google Play Store, and Web.',
    'Architected and implemented a solution to deploy services and databases to provide data to native and progressive web apps.',
    'Introduced new ways of working to a professionally inexperienced team.',
    'Implemented automated browser testing framework to guarantee e2e functionality.',
    'Configured a monorepo to allow fluid development of the app and its corresponding microservices and database.'
  ],
  technologies: [
    REACT_NATIVE,
    REDUX,
    RXJS,
    DOCKER,
    GITLABCI,
    CYPRESS,
    MYSQL,
    NODE,
    TDD,
    SOLUTION_ARCH,
    MENTORING,
    PAIR_PROGRAMMING
  ]
}

const DAIRY: Experience = {
  jobTitle: 'Senior Software Engineer',
  projectDescription:
    "To consult a small development team on how to move forward with a legacy app with performance and stability issues for the USA's leading dairy logistics & management software company.",
  dates: {
    start: new Date('2020-03-01'),
    end: new Date('2021-09-01')
  },
  company: {
    name: 'Clevertech (Dairy.com)',
    image:
      '/img/ct.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.WHISTLER,
  locale: Locale.REMOTE,

  contributions: [
    'Analysed existing codebase and provided a recommendation to rebuild their legacy app, taking into account the best interest of the business and the development team.',
    'Led a small team of developers across different timezones to rebuild the app, moving from an unsupported version of Ionic (Angular) to a supported version (React).',
    'Wrote and maintained documentation throughout the development process.',
    'Gave weekly demonstrations on app rebuild progress.'
  ],
  technologies: [IONIC, REACT, JEST, ENZYME, TDD, SOLUTION_ARCH]
}

const PATH: Experience = {
  jobTitle: 'Software Engineer (Senior)',
  projectDescription:
    'To contribute to the rapid development of a SaaS HR solution for SMEs',
  dates: {
    start: new Date('2019-09-01'),
    end: new Date('2020-03-01')
  },
  company: {
    name: 'Path (NatWest Ventures)',
    image:
      '/img/path.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.IN_OFFICE,

  contributions: [
    'Implemented new product features up and down the stack.',
    'Guaranteed product stability by working with TDD.',
    'Ran data migrations when necessary to make breaking changes to the DB.',
    'Configured CI and CD processes to test, build and deploy services to AWS infrastructure.'
  ],
  technologies: [
    NODE,
    MONGO,
    REACT,
    REDUX,
    AWS_EKS,
    KUBERNETES,
    GITLABCI,
    JEST,
    ENZYME,
    SUPERTEST,
    TDD,
    PAIR_PROGRAMMING,
    RESTFUL_API,
    SCRUM
  ]
}

const MENDELEY: Experience = {
  jobTitle: 'Software Engineer',
  projectDescription:
    'To contribute to the development of a modern rebuild of their hugely popular reference management software',
  dates: {
    start: new Date('2019-06-01'),
    end: new Date('2019-09-01')
  },
  company: {
    name: 'Elsevier (Mendeley)',
    image: '/img/elsevier.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.IN_OFFICE,
  contributions: [
    'Implemented features into a complex product that manipulated PDF files and used observables for real-time collaboration.',
    'Maintained product stability by writing and maintaining unit & integration tests to satisfy new product requirements.',
    'Worked with a dedicated QA team to write end-to-end tests using Cucumber.js.'
  ],
  technologies: [
    REACT,
    REDUX,
    RXJS,
    SCSS,
    TYPESCRIPT,
    NODE,
    JEST,
    ENZYME,
    TDD,
    PAIR_PROGRAMMING,
    SCRUM
  ]
}

const ELSEVIER_JOURNALS: Experience = {
  jobTitle: 'Software Engineer',
  projectDescription:
    'To contribute to the development of a domain-agnostic solution to deliver different science journals through a single-page application.',
  dates: {
    start: new Date('2018-12-01'),
    end: new Date('2019-06-01')
  },
  company: {
    name: 'Elsevier',
    image:
      '/img/elsevier.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.IN_OFFICE,
  contributions: [
    'Participated in technical design discussions with Tech Lead.',
    'Implemented React rendering from deep abstract content nodes from a GraphQL endpoint, allowing for partial content loading & caching.',
    'Implemented and maintained unit & integration tests to ensure product stability.',
    'Liaised with the Product Managers & Designers to gather functional requirements.'
  ],
  technologies: [
    REACT,
    GRAPHQL,
    JEST,
    ENZYME,
    TYPESCRIPT,
    NODE,
    SCSS,
    TDD,
    SOLUTION_ARCH,
    GRAPHQL_API,
    SCRUM
  ]
}

const USWITCH: Experience = {
  projectDescription:
    "To be part of a team reinventing an antiquated, poorly converting user journey for a particular client's broadband.",
  dates: {
    start: new Date('2018-08-01'),
    end: new Date('2018-12-01')
  },
  jobTitle: 'Senior Front End Engineer',
  company: {
    name: 'uSwitch',
    image:
      '/img/uswitch.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.IN_OFFICE,
  contributions: [
    'Implemented server-side integration with an event-based asynchronous browser automation platform that allowed interaction with suppliers with no APIs to integrate directly.',
    'Developed user interface features according to design.',
    'Collaborated with third-party platform providers to optimise integration.',
    'Worked with Product Managers to refine requirements.'
  ],
  technologies: [
    REACT,
    REDUX,
    NODE,
    KOA,
    DOCKER,
    KUBERNETES,
    CYPRESS,
    JEST,
    SCSS,
    TDD
  ]
}

const CTM: Experience = {
  projectDescription:
    'To be a senior contributor of a team building a service to provide competitive insurance quotes to customers ahead of their renewal date (now known as AutoSergei)',
  dates: {
    start: new Date('2018-02-01'),
    end: new Date('2018-08-01')
  },
  jobTitle: 'Senior Software Engineer',
  company: {
    name: 'comparethemarket.com',
    image:
      '/img/ctm.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.LONDON,
  locale: Locale.IN_OFFICE,
  contributions: [
    'Developed features in React according to designs.',
    'Assisted the various junior members of the team.',
    'Collaborated with Architects to design an optimal solution.'
  ],
  technologies: [
    REACT,
    REDUX,
    NODE,
    DOCKER,
    KAFKA,
    MONGO,
    STYLED_COMPONENTS,
    TDD,
    SOLUTION_ARCH,
    MENTORING,
    PAIR_PROGRAMMING,
    RESTFUL_API,
    SCRUM
  ]
}

const CREDIT_SUISSE: Experience = {
  projectDescription:
    'To implement a complex dynamic form to allow bank staff to assess new client risk more swiftly and accurately than ever before.',
  dates: {
    start: new Date('2017-03-01'),
    end: new Date('2017-12-01')
  },
  jobTitle: 'Software Engineer',
  company: {
    name: 'Credit Suisse',
    image:
      '/img/cs.webp'
  },
  employment: Employment.CONTRACT,
  location: Location.ZURICH,
  locale: Locale.IN_OFFICE,
  contributions: [
    'Brought in Single Page Application expertise that the existing team lacked to develop a modern and fluid web experience.',
    'Worked with Engineering Manager and Product Manager to refine functional and non-functional requirements.',
    'Rapidly developed MVP, ensuring product stability with automated browser tests and managed manual deployment process '
  ],
  technologies: [REACT, REDUX, CYPRESS, NODE, JEST, SCSS, TDD, RESTFUL_API]
}

const BBC: Company = {
  name: 'BBC',
  image:
    '/img/bbc.webp'
}

const BBC_THREE: Experience = {
  jobTitle: 'Javascript Software Engineer (BBC Three)',
  projectDescription:
    'To be a key contributor of a team rebuilding the BBC Three website.',
  dates: {
    start: new Date('2016-09-01'),
    end: new Date('2017-03-01')
  },
  company: BBC,
  employment: Employment.CONTRACT,
  location: Location.MANCHESTER,
  locale: Locale.IN_OFFICE,
  contributions: [
    'Participated in technical workshops to discuss the trade-offs and complexities of different technical approaches.',
    'Developed and maintained features in accordance with product and design requirements.',
    'Contributed stable and quality code to company-wide, high-availability, scalable data services.'
  ],
  technologies: [
    REACT,
    REDUX,
    NODE,
    VANILLA_JS,
    AWS_S3,
    BABEL,
    DOCKER,
    SCSS,
    TDD,
    PAIR_PROGRAMMING,
    RESTFUL_API,
    SCRUM
  ]
}

const BBC_IPLAYER: Experience = {
  jobTitle: 'Javascript Software Engineer (iPlayer for TV)',
  projectDescription:
    'To develop cutting-edge features on the high-traffic and highly available iPlayer app for Smart TVs ',
  dates: {
    start: new Date('2015-10-01'),
    end: new Date('2016-08-01')
  },
  company: BBC,
  employment: Employment.CONTRACT,
  location: Location.MANCHESTER,
  locale: Locale.IN_OFFICE,
  contributions: [
    'Developed POCs for key prospective features, ensuring functionality on known problematic/legacy devices.',
    'Implemented quality and stable code, always in a Pair Programming and TDD environment.',
    'Performed in-person code reviews to ensure product requirements were being met in full.',
    'Regularly discussed and optimising team ways of working.',
    'Contributed features to a large codebase, continuously worked on by multiple scrum teams.'
  ],
  technologies: [
    REACT,
    REDUX,
    NODE,
    VANILLA_JS,
    AWS_S3,
    BABEL,
    DOCKER,
    SCSS,
    TDD,
    PAIR_PROGRAMMING,
    RESTFUL_API,
    SCRUM
  ]
}

export const experiences: Experiences = [
  EUROSTAR,
  GFK,
  NEWSUK_ZEPHR,
  WORLD_REMIT,
  NEWSUK_MAIN,
  RISKBASE,
  DAIRY,
  PATH,
  MENDELEY,
  ELSEVIER_JOURNALS,
  USWITCH,
  CTM,
  CREDIT_SUISSE,
  BBC_THREE,
  BBC_IPLAYER
  // QA
]
