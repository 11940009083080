import { type Education } from './types'

const UNIVERSITY: Education = {
  institution: 'Liverpool John Moores University',
  qualification: 'BSc in Computer Animation and Visualisation'
}

const COLLEGE: Education = {
  institution: 'Runshaw College',
  qualification:
    'BTEC National Diploma (Triple Distinction) in ICT Practitioners - Business and ICT'
}

const HIGH_SCHOOL: Education = {
  institution: 'Hutton Grammar School',
  qualification: "10 GCSE's including Maths, English, and Science"
}

export const educations: Education[] = [UNIVERSITY, COLLEGE, HIGH_SCHOOL]
